import { graphql } from 'gatsby'
import React from 'react'
import Card from '../components/Card/Card'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Spacer from '../components/Spacer'

const personal = ({ data: { page } }) => {
  const personal = page.personal.personal
  return (
    <Layout>
      <Seo
        title={'Personal'}
        description={
          'Vi är ett starkt och kreativt team med lång erfarenhet och stor passion för yrket i Åkersberga, Stockholm. Vårt mål är alltid att ge dig den ultimata frisörupplevelsen - genom att vara experter inom vårt område och ge dig skräddarsydda behandlingar.'
        }
      />
      <Spacer />
      <div className="container">
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-3 lg:gap-6">
          {personal?.length &&
            personal.map((item, index) => (
              <Card key={index} title={item.namn} src={item.bild} />
            ))}
        </div>
      </div>
      <Spacer />
    </Layout>
  )
}

export const query = graphql`
  query Personal {
    page: wpPage(title: { eq: "Personal" }) {
      id
      hero {
        bild {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      personal {
        personal {
          namn
          bild {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 192, quality: 100)
              }
            }
          }
        }
      }
    }
  }
`

export default personal
